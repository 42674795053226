import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import "./samplePage.css";
import {
  getSubscriptionStatus,
  checkIfUserOwnsSample,
  updateSample,
} from "../api_calls";

export default function SampleInfoPage() {
  const navigate = useNavigate();
  const { id } = useParams(); // id given by CA platform captured from url
  const { user } = useAuth0(); // user currently logged-in
  const [edit, setEdit] = useState(false); // edit mode
  const [buyState, setBuyState] = useState(true); // BUY button
  const [canEdit, setCanEdit] = useState(true); // EDIT && DELETE button
  const [sample, setSample] = useState({}); // sample data called from database
  const [fieldValues, setFieldValues] = useState({}); // holds edits made by user

  const setEditSaveToggle = () => {
    setEdit(!edit);
  };

  /**
   *   Check if user currently logged-in is a member of a lab group with an 'Active' subscription
   *   If so, give them permission to buy a sample
   */
  useEffect(() => {
    getSubscriptionStatus(user)
      .then((response) => {
        response.length
          ? response[0].subscription === "Active"
            ? setBuyState(true)
            : setBuyState(false)
          : setBuyState(false);
      })
      .catch((err) => console.log(err));
  }, [user]);

  /**
   *   Check if user currently logged-in is the owner of the sample
   *   If so, give them permission to edit sample
   */
  useEffect(() => {
    checkIfUserOwnsSample(id)
      .then((response) => {
        response[0].user === user.email ? setCanEdit(true) : setCanEdit(false);
      })
      .catch((err) => console.log(err));
  }, [user, id]);

  /**
   *   Get sample data with useParams() CA_id
   */
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getSample`,
        {
          method: "POST",
          mode: `cors`,
          body: JSON.stringify({
            id: id, //CA_id
          }),
          headers: {
            "Access-Control-Request-Method": "POST",
            "Access-Control-Request-Headers": "Content-Type, Accept",
            Origin: "*",
            "Content-Type": "application/json",
          },
        }
      );
      response = await response.json();
      //console.log(response[0]);
      setSample(response[0]);
      setFieldValues(response[0]);
    }

    fetchMyAPI();
  }, [id]);

  /**
   *  Delete the sample (this button only appears to the sample owner)
   */
  const deleteSample = () => {
    async function deleteSample() {
      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/deleteSample`,
        {
          method: "POST",
          mode: `cors`,
          body: JSON.stringify({
            id: id, //CA_id
          }),
          headers: {
            "Access-Control-Request-Method": "POST",
            "Access-Control-Request-Headers": "Content-Type, Accept",
            Origin: "*",
            "Content-Type": "application/json",
          },
        }
      );
      response = await response.json();
      navigate("/findSample");
      //console.log(response[0]);
    }
    deleteSample();
  };

  /**
   *  Form Submission
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    updateSample(fieldValues, id);
    window.location.reload(true);
  };

  return (
    <>
      <form className="form-content" onSubmit={handleSubmit}>
        <div className="border border-solid m-3 pb-3 md:ml-10 md:mr-10 md:mt-5 p-3 md:pb-5 rounded bg-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)]">
          <div className="flex flex-wrap">
            <div className="w-full text-xl mb-3">
              Central Alliant ID:&nbsp;&nbsp;&nbsp;{sample.CA_id}
            </div>
            <div className="w-full md:w-1/2 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Laboratory</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="lab_name"
                    value={fieldValues.lab_name}
                    placeholder={sample.lab_name}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        lab_name: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Laboratory</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {sample.lab_name}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">CLIA #</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="clia_number"
                    value={fieldValues.clia_number}
                    placeholder={sample.clia_number}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        clia_number: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">CLIA #</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {sample.clia_number}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/4 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Accession #</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="accession_number"
                    value={fieldValues.accession_number}
                    placeholder={sample.accession_number}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        accession_number: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Accession #</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {sample.accession_number}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/6 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Panel</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="panel"
                    value={fieldValues.panel}
                    placeholder={sample.panel}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        panel: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Panel</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {sample.panel}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Organism Detected</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="organism_detected"
                    value={fieldValues.organism_detected}
                    placeholder={sample.organism_detected}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        organism_detected: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Organism Detected</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    <i className="text-mexican-orange font-bold">
                      {sample.organism_detected}
                    </i>
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/6 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">CT Level</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="CT_level"
                    value={fieldValues.CT_level}
                    placeholder={sample.CT_level}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        CT_level: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">CT Level</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    <div className="text-mexican-orange font-bold">
                      {sample.CT_level}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="w-full md:w-1/4 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Collection Date</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="collection_date"
                    value={fieldValues.collection_date}
                    placeholder={sample.collection_date}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        collection_date: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Collection Date</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {sample.collection_date}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/4 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Collection Method</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="collection_method"
                    value={fieldValues.collection_method}
                    placeholder={sample.collection_method}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        collection_method: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Collection Method</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {sample.collection_method}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/4 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Extraction Date</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="extraction_date"
                    value={fieldValues.extraction_date}
                    placeholder={sample.extraction_date}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        extraction_date: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Extraction Date</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {sample.extraction_date}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/4 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Extraction Method</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="extraction_method"
                    value={fieldValues.extraction_method}
                    placeholder={sample.extraction_method}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        extraction_method: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Extraction Method</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {sample.extraction_method}
                  </div>
                </div>
              )}
            </div>

            <div className="w-full md:w-1/3 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Extraction Technician</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="extraction_tech"
                    value={fieldValues.extraction_tech}
                    placeholder={sample.extraction_tech}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        extraction_tech: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Extraction Technician</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {sample.extraction_tech}
                  </div>
                </div>
              )}
            </div>

            <div className="w-full md:w-1/3 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">PCR Technician</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="pcr_tech"
                    value={fieldValues.pcr_tech}
                    placeholder={sample.pcr_tech}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        pcr_tech: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">PCR Technician</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {sample.pcr_tech}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 pt-2">
              <div>
                <label className="text-gray-400">Sample Registrant</label>
                <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                  {sample.user}
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Instrument Used</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="instrument_used"
                    value={fieldValues.instrument_used}
                    placeholder={sample.instrument_used}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        instrument_used: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Instrument Used</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {sample.instrument_used}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/6 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400"># of Alliquots</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="alliquot_num"
                    value={fieldValues.alliquot_num}
                    placeholder={sample.alliquot_num}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        alliquot_num: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400"># of Alliquots</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {sample.alliquot_num}
                  </div>
                </div>
              )}
            </div>
            {/** BUTTON BLOCK **/}
            <div className="flex w-full md:w-1/3 justify-center gap-x-2 py-3 px-4">
              {buyState && !edit ? (
                <button
                  type="button"
                  disabled
                  className="mt-5 mb-2 text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Buy
                </button>
              ) : null}
              {canEdit && !edit ? (
                <button
                  type="button"
                  className="mt-5 mb-2 text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  onClick={setEditSaveToggle}
                >
                  Edit
                </button>
              ) : null}
              {canEdit && edit ? (
                <button
                  type="submit"
                  className="mt-5 mb-2 text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Save
                </button>
              ) : null}
              {canEdit && !edit ? (
                <button
                  type="button"
                  className="mt-5 mb-2 text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  onClick={deleteSample}
                >
                  Delete
                </button>
              ) : null}
            </div>
            <div className="w-full md:w-3/4 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Notes</label>
                  <textarea
                    rows="4"
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="notes"
                    value={fieldValues.notes}
                    placeholder={sample.notes}
                    required
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        notes: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Notes</label>
                  <div
                    className="appearance-none mb-10 block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight"
                    rows="4"
                    readOnly
                  >
                    {sample.notes}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
