import { flexRender } from '@tanstack/react-table';
/*
https://www.reddit.com/r/sveltejs/comments/11parc9/i_use_tanstack_table_how_to_render_a_component_in/
import YourComponent from './yourcomponent.svelte';

let props = { color: value > 600 ? 'red' : 'green', label: value };
let tableCol: ColumnDef<any>[] = [
    {
        header: 'name'
        accessorKey: 'name',
        cell: flexRender(YourComponent, props)
    }
]
*/
export const columnDef = [
  {
    accessorKey: "lab_name",
    header: "Lab_Name",
  },
  {
    accessorKey: "panels",
    header: "Panels_Offered",
    cell: (info) => JSON.stringify(info.getValue()),
  },
  {
    accessorKey: "clia_number",
    header: "CLIA_#",
  },
  {
    accessorKey: "user",
    header: "Email",
  },
  {
    accessorKey: "phone_no",
    header: "Phone_#",
  },
];