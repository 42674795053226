import Accordion from "../accordion/accordion";
import "./services.css";

export default function Services() {
  const accordionData = [
    {
      title: "Laboratory Operations",
      content: `Expand your laboratory's reach with Alliant Lab Consulting's tailored Outreach Solutions. We specialize in bridging gaps between labs and the communities they serve, ensuring seamless service delivery. From developing efficient patient referral systems to implementing state-of- the-art IT solutions, our team assists in every step of the outreach process. Elevate your lab's presence, connect better with patients and healthcare providers, and foster growth with our dedicated outreach expertise. Join hands with Alliant and redefine your lab's community impact.`,
    },
    {
      title: "Laboratory Outreach Solutions",
      content: `Expand your laboratory's reach with Alliant Lab Consulting's tailored Outreach Solutions. We specialize in bridging gaps between labs and the communities they serve, ensuring seamless service delivery. From developing efficient patient referral systems to implementing state-of- the-art IT solutions, our team assists in every step of the outreach process. Elevate your lab's presence, connect better with patients and healthcare providers, and foster growth with our dedicated outreach expertise. Join hands with Alliant and redefine your lab's community impact.`,
    },
    {
      title: "Revenue Cycle Management",
      content: `Achieve financial stability and streamline your lab's revenue processes with Alliant Lab Consulting's Revenue Cycle Management services. We provide strategic solutions to manage billing, collections, and patient accounting efficiently. Our team ensures reduced overheads, maximized revenue, and faster turnaround times. Leveraging our deep industry insights, we tackle challenges in reimbursement and regulatory compliance, driving profitability and sustainable growth. Let Alliant be your partner in navigating the complex financial landscape of laboratory operations.`,
    },
    {
      title: "Analytics and Reporting Tools",
      content: `Unlock the power of data with Alliant Lab Consulting's Analytics and Reporting Tools. Our services provide labs with actionable insights, ensuring informed decision-making at every turn. We harness advanced data processing techniques to visualize trends, monitor performance metrics, and flag potential areas of concern. With a focus on user-friendly interfaces and detailed reporting capabilities, our tools are designed to empower your lab's operations and strategic planning. Partner with Alliant and transform raw data into your lab's most valuable resource.`,
    },
    {
      title: "Quality Management",
      content: `Elevate your laboratory standards with Alliant Lab Consulting's Quality Management services. We prioritize precision, accuracy, and reliability in every laboratory operation. Our team collaborates closely with labs to implement robust quality control systems, ensure compliance with industry regulations, and promote continuous improvement. With our expert guidance, labs can expect to see enhanced performance, reduced error rates, and heightened confidence in their results. Trust Alliant to champion excellence in your lab's quality journey.`,
    },
    {
      title: "System Laboratory Alignment",
      content: `Harmonize your lab operations with Alliant Lab Consulting's System Laboratory Alignment services. Recognizing the intricacies of integrating multiple lab systems, we specialize in creating synergies that ensure seamless functionality and efficient workflows. Our team assesses, plans, and implements strategies tailored to align your lab's systems, fostering improved communication, reduced redundancies, and maximized productivity. With Alliant as your ally, experience a unified laboratory environment that's poised for success.`,
    },
    {
      title: "Healthcare Compliance",
      content:
        "Navigate the complexities of healthcare regulations with confidence, thanks to Alliant Lab Consulting's Healthcare Compliance services. Our dedicated team stays ahead of evolving compliance landscapes, ensuring your laboratory remains aligned with industry standards and legal mandates. We offer comprehensive assessments, training sessions, and continuous support to safeguard your lab's operations against potential risks. Rely on Alliant to fortify your lab's compliance framework and foster an environment of trust and reliability.",
    },
  ];
  return (
    <>
      <div className="border border-solid m-3 pb-3 md:ml-10 md:mr-10 md:mt-5 md:p-3 md:pb-5 rounded bg-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)]">
        <div className="text-2xl md:text-4xl text-black font-light pl-3 pr-3 md:mb-3 pt-3">
          Give your lab a new path forward.
        </div>
        <br></br>
        <div className="md:grid md:grid-cols-[38%_62%]">
          <div className="pl-6 pr-6">
            <br className="mobile-break"></br>
            <div className="md:font-medium">
              The Alliant Lab Consulting team will assist you in reaching your
              laboratory goals. We recognize that there is no one-size-fits all
              solution for dynamic organizations, which is why we take a
              personalized approach with every client. Our mission is to amplify
              your lab's capabilities, uncover new prospects, and refine your
              laboratory procedures.
            </div>
            <br className="mobile-break"></br>
            <br></br>
            <div className="font-bold">
              Here are some of the tools and solutions we offer.
            </div>
            <br></br>
          </div>
          <div>
            <div className="accordion">
              {accordionData.map(({ title, content }) => (
                <div key={title}>
                  <Accordion title={title} content={content} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
