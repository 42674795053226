import { useState, useEffect } from "react";
import FormErrors from "../formErrors";
import "./labRegistration.css";
import Select from "react-select";
import chroma from "chroma-js";
import Payment from "../payment";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";

export default function LabRegistration() {
  const { user } = useAuth0(); // user filling in the form
  const navigate = useNavigate();
  const [cliaUniq, setCliaUniq] = useState(true); //false=NOTunique, true=ISunique

  /* FIELD VALUES */
  let [fieldValues, setFieldValues] = useState({
    labName: "",
    streetAddress: "",
    city: "",
    state: "",
    zipcode: "",
    cliaNumber: "",
    panels: [],
    phoneNo: "",
    website: "",
  });
  /* FIELD VALUES VALID? */
  let [fieldValuesValid, setFieldValuesValid] = useState({
    labName: false,
    streetAddress: false,
    city: false,
    state: false,
    zipcode: false,
    cliaNumber: false,
    panels: false,
    phoneNo: false,
    website: true, // field isn't required. It can submit empty.
  });
  /* ERROR TEXT (IF ANY) */
  let [formErrors, setFormErrors] = useState({
    labName: "",
    streetAddress: "",
    city: "",
    state: "",
    zipcode: "",
    cliaNumber: "",
    panels: "",
    phoneNo: "",
    website: "",
  });
  /* FORM HAS BEEN SUBMITTED STATE - controls toasts */
  let [formValid, setFormValid] = useState(false);

  /***** 

    FIELD VALIDATORS

  *****/

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "labName":
        fieldValuesValid.labName = value.length >= 3 && value.length <= 100;
        formErrors.labName = fieldValuesValid.labName ? "" : " Required";
        break;
      case "streetAddress":
        fieldValuesValid.streetAddress =
          value.length >= 5 && value.length <= 100;
        formErrors.streetAddress = fieldValuesValid.streetAddress
          ? ""
          : " Required";
        break;
      case "city":
        fieldValuesValid.city = value.length >= 3 && value.length <= 100;
        formErrors.city = fieldValuesValid.city ? "" : " Required";
        break;
      case "state":
        // option loaded from api. no validator required.
        break;
      case "zipcode":
        if (value === "") {
          fieldValuesValid.zipcode = false;
          formErrors.zipcode = " Required";
        } else if (value.length === 5) {
          fieldValuesValid.zipcode = true;
          formErrors.zipcode = "";
        } else if (value.length > 5) {
          fieldValuesValid.zipcode = false;
          formErrors.zipcode = "Too long";
        }
        break;
      case "cliaNumber":
        if (value === "") {
          fieldValuesValid.cliaNumber = false;
          formErrors.cliaNumber = " Required";
        } else if (value.length < 10) {
          fieldValuesValid.cliaNumber = false;
          formErrors.cliaNumber = " Needs to be 10 characters";
        } else if (value.length === 10) {
          fieldValuesValid.cliaNumber = true;
          formErrors.cliaNumber = "";
        } else if (value.length > 10) {
          fieldValuesValid.cliaNumber = false;
          formErrors.cliaNumber = "Too long";
        }
        break;
      case "panels":
        // option loaded from api. no validator required.
        break;
      case "phoneNo":
        if (value === "") {
          formErrors.phoneNo = " Required";
          fieldValuesValid.phoneNo = false;
        } else {
          let pho = value
            .toLowerCase()
            .match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im);
          if (pho) {
            fieldValuesValid.phoneNo = true;
            formErrors.phoneNo = "";
          } else {
            fieldValuesValid.phoneNo = false;
            formErrors.phoneNo = "Incorrect phone number format";
          }
        }
        break;
      case "website":
        fieldValuesValid.website = /(^https:\/\/)|(^\s*$)/.test(value);
        formErrors.website = fieldValuesValid.website
          ? ""
          : " URL requried to begin with https:// ";
        break;
      default:
        break;
    }

    setFormErrors(formErrors);
    setFieldValuesValid(fieldValuesValid);
  };

  /**
   *  Get states from the api
   */
  let [stateOptions, setStateOptions] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/states`, { mode: `cors` })
      .then((res) => res.json())
      .then((states) => {
        for (const key of states) {
          let obj = {
            value: key["state_abbr"],
            label: key["state_abbr"],
            type: "state",
          };
          setStateOptions((stateOptions) => [...stateOptions, obj]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  /**
   *  Get panels from the api
   */
  const [panelOptions, setPanelOptions] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/panels`, { mode: `cors` })
      .then((res) => res.json())
      .then((pan) => {
        for (const key of pan) {
          let obj = {
            value: key["panel"],
            label: key["label"],
            color: key["color"],
          };
          setPanelOptions((panelOptions) => [...panelOptions, obj]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  /*****
   
    MULTI-SELECT COLORED DROPDOWN
   
  *****/

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      lineHeight: "1.8rem",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: data.color,
        color: "white",
      },
    }),
  };

  /*****
  
    HANDLE DROPDOWN STATE CHANGES

  *****/

  const [stateSelectedAbbrOption, setSelectedStateAbbr] = useState(null);
  // eslint-disable-next-line
  const [panelSelectedOptions, setPanelSelectedOptions] = useState(null);

  const handleChange = (item) => {
    if (item.type === "state") {
      setSelectedStateAbbr(item);
      setFieldValues({ ...fieldValues, state: item.value });
      setFieldValuesValid({ ...fieldValuesValid, state: true });
    } else {
      // panels
      setPanelSelectedOptions(item);
      let arr = { panels: [] };
      for (let i = 0; i < item.length; i++) {
        arr.panels.push(item[i].value);
      }
      setFieldValues({ ...fieldValues, panels: arr });
      setFieldValuesValid({ ...fieldValuesValid, panels: true });
    }
  };

  /**
   *   Check if CLIA number is unique
   */
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/checkCLIA`,
        {
          method: "POST",
          mode: `cors`,
          body: JSON.stringify({
            clia_no: fieldValues.cliaNumber,
          }),
          headers: {
            "Access-Control-Request-Method": "POST",
            "Access-Control-Request-Headers": "Content-Type, Accept",
            Origin: "*",
            "Content-Type": "application/json",
          },
        }
      );

      response = await response.json();
      response.length ? setCliaUniq(false) : setCliaUniq(true);
    }
    fetchMyAPI();
  }, [fieldValues.cliaNumber]);

  /*****
   
    FORM SUBMISSION LOGIC
  
  *****/

  // on form submission...
  const handleSubmit = (event) => {
    event.preventDefault();

    console.log(fieldValues); // DO NOT DELETE
    console.log(fieldValuesValid); // DO NOT DELETE

    var formState =
      fieldValuesValid.labName &&
      fieldValuesValid.streetAddress &&
      fieldValuesValid.city &&
      fieldValuesValid.state &&
      fieldValuesValid.zipcode &&
      fieldValuesValid.cliaNumber &&
      fieldValuesValid.panels &&
      fieldValuesValid.phoneNo &&
      fieldValuesValid.website;

    //console.log(formState);
    //formState = false; // prevent submission while trying to fill states
    let panelString = formState ? JSON.stringify(fieldValues.panels) : null;

    console.log(cliaUniq);

    if (formState && cliaUniq) {
      fetch(`${process.env.REACT_APP_SERVER_URL}/insertLab`, {
        method: "POST",
        mode: `cors`,
        body: JSON.stringify({
          lab_name: fieldValues.labName,
          street_address: fieldValues.streetAddress,
          city: fieldValues.city,
          state: fieldValues.state,
          zipcode: fieldValues.zipcode,
          clia_number: fieldValues.cliaNumber,
          panels: panelString,
          phone_no: fieldValues.phoneNo,
          website: fieldValues.website,
          user: user.email,
        }),
        headers: {
          "Access-Control-Request-Method": "POST",
          "Access-Control-Request-Headers": "Content-Type, Accept",
          Origin: "*",
          "Content-Type": "application/json",
        },
      })
        .then((resp) => {
          setFormValid(true);

          emailjs
            .send(
              process.env.REACT_APP_SERVICE_ID,
              process.env.REACT_APP_SUBSCRIBE_TEMPLATE_ID,
              { reply_to: user.email },
              process.env.REACT_APP_PUBLIC_KEY
            )
            .then(
              function (response) {
                console.log(
                  "Email to lab subscriber SUCCESS!", // DO NOT DELETE
                  response.status,
                  response.text
                );
              },
              function (error) {
                console.log("Email to lab subscriber FAILED.", error); // DO NOT DELETE
              }
            );

          const delay = 5000;
          setTimeout(() => {
            navigate("/labDirectory");
          }, delay);
          return resp.json();
        })
        .then(function (data) {
          console.log(data); // DO NOT DELETE
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  return (
    <>
      <div>
        <form className="form-content" onSubmit={handleSubmit}>
          <div className="border border-solid m-3 pb-3 md:ml-10 md:mr-10 md:mt-5 md:p-3 md:pb-5 rounded bg-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)]">
            <div className="flex flex-wrap">
              <div className="w-full text-xl mb-2 p-3">Lab Registration</div>
              <div className="w-full md:w-1/2 px-3 pt-2">
                <label>Laboratory Name</label>
                <input
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="labName"
                  value={fieldValues.labName}
                  placeholder="Lab Name"
                  required
                  onChange={(e) => {
                    setFieldValues({ ...fieldValues, labName: e.target.value });
                    validateField("labName", e.target.value);
                  }}
                ></input>
                <div className="panel panel-default">
                  <FormErrors formErrors={formErrors} fieldName="labName" />
                </div>
              </div>
              <div className="w-full md:w-1/4 px-3 pt-2">
                <label>CLIA #</label>
                <input
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="cliaNumber"
                  value={fieldValues.cliaNumber}
                  placeholder="CLIA # e.g. 12D3456789"
                  required
                  onChange={(e) => {
                    setFieldValues({
                      ...fieldValues,
                      cliaNumber: e.target.value,
                    });
                    validateField("cliaNumber", e.target.value);
                  }}
                ></input>
                <div className="panel panel-default">
                  <FormErrors formErrors={formErrors} fieldName="cliaNumber" />
                </div>
              </div>
              <div className="w-full md:w-1/3 px-3 pt-2">
                <label>Street Address</label>
                <input
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="streetAddress"
                  value={fieldValues.streetAddress}
                  placeholder="Street Address"
                  required
                  onChange={(e) => {
                    setFieldValues({
                      ...fieldValues,
                      streetAddress: e.target.value,
                    });
                    validateField("streetAddress", e.target.value);
                  }}
                ></input>
                <div className="panel panel-default">
                  <FormErrors
                    formErrors={formErrors}
                    fieldName="streetAddress"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/4 px-3 pt-2">
                <label>City</label>
                <input
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="city"
                  value={fieldValues.city}
                  placeholder="City"
                  required
                  onChange={(e) => {
                    setFieldValues({ ...fieldValues, city: e.target.value });
                    validateField("city", e.target.value);
                  }}
                ></input>
                <div className="panel panel-default">
                  <FormErrors formErrors={formErrors} fieldName="city" />
                </div>
              </div>
              <div className="w-full md:w-1/6 px-3 pt-2">
                <label>State</label>
                <Select
                  value={stateSelectedAbbrOption}
                  onChange={handleChange}
                  options={stateOptions}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "#e5e7eb",
                      lineHeight: "2rem",
                      borderColor: state.isFocused ? "none" : "none",
                      borderWidth: 0,
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral50: "#9CA3AF", // placeholder color
                    },
                  })}
                />
              </div>
              <div className="w-full md:w-1/6 px-3 pt-2">
                <label>Zipcode</label>
                <input
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="zipcode"
                  value={fieldValues.zipcode}
                  placeholder="Zipcode"
                  required
                  onChange={(e) => {
                    setFieldValues({ ...fieldValues, zipcode: e.target.value });
                    validateField("zipcode", e.target.value);
                  }}
                ></input>
                <div className="panel panel-default">
                  <FormErrors formErrors={formErrors} fieldName="zipcode" />
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3 pt-2">
                <label>Panels Offered</label>
                <Select
                  closeMenuOnSelect={false}
                  onChange={handleChange}
                  //defaultValue={[panelOptions[0], panelOptions[1]]}
                  isMulti
                  options={panelOptions}
                  styles={colourStyles}
                  allowSelectAll={true}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 pt-2"></div>
              <div className="w-full md:w-1/4 px-3 pt-2">
                <label>Phone Number</label>
                <input
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="phoneNo"
                  value={fieldValues.phoneNo}
                  placeholder="###-###-####"
                  required
                  onChange={(e) => {
                    setFieldValues({ ...fieldValues, phoneNo: e.target.value });
                    validateField("phoneNo", e.target.value);
                  }}
                ></input>
                <div className="panel panel-default">
                  <FormErrors formErrors={formErrors} fieldName="phoneNo" />
                </div>
              </div>
              <div className="w-full md:w-2/3 px-3 pt-2">
                <label>Website</label>
                <input
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="website"
                  value={fieldValues.website}
                  placeholder="https://"
                  onChange={(e) => {
                    setFieldValues({
                      ...fieldValues,
                      website: e.target.value,
                    });
                    validateField("website", e.target.value);
                  }}
                ></input>
                <div className="panel panel-default">
                  <FormErrors formErrors={formErrors} fieldName="website" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <Payment />
          </div>
          {
            /*
            This needs to trigger an email to the subscriber with a receipt and about setting up users to their lab group
            */
            formValid ? (
              <div className="flex flex-row w-full text-center pl-3 pt-5 pb-10">
                <div className="w-full md:w-3/4"></div>
                <div className="w-full md:w-1/4 mr-10">
                  <div className="text-center pt-2 bg-[#ccfbf1] rounded h-11 shadow-[0px_1px_1px_0px_rgba(0,_0,_0,_0.10)]">
                    Success! Your lab has been registered.
                  </div>
                </div>
              </div>
            ) : cliaUniq ? (
              <div className="flex flex-row w-full text-center pl-3 pt-5 pb-10">
                <div className="w-full md:w-3/4"></div>
                <div className="w-full md:w-1/4">
                  <button
                    type="submit"
                    className="text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-[0px_1px_1px_0px_rgba(0,_0,_0,_0.1)]"
                  >
                    Register Lab
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex flex-row w-full text-center pl-3 pt-5 pb-10">
                <div className="w-full md:w-3/4"></div>
                <div className="w-full md:w-1/4 mr-10">
                  <div className="text-center pt-2 rounded h-11 shadow-[0px_1px_1px_0px_rgba(0,_0,_0,_0.10)]">
                    The CLIA# is not unique.
                  </div>
                </div>
              </div>
            )
          }
        </form>
      </div>
    </>
  );
}
