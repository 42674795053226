// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
    position: relative;
    display: inline-block;
  }
  
.tooltip .tooltiptext {
    visibility: hidden;
    width: 80px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 105%;
    margin-left: -60px;
    font-size: small;
    margin: 0px 8px;
  } 
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }`, "",{"version":3,"sources":["webpack://./src/components/tooltip/tooltip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;EACvB;;AAEF;IACI,kBAAkB;IAClB,WAAW;IACX,uBAAuB;IACvB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".tooltip {\n    position: relative;\n    display: inline-block;\n  }\n  \n.tooltip .tooltiptext {\n    visibility: hidden;\n    width: 80px;\n    background-color: black;\n    color: #fff;\n    text-align: center;\n    border-radius: 6px;\n    padding: 8px 0;\n    position: absolute;\n    z-index: 1;\n    top: -5px;\n    right: 105%;\n    margin-left: -60px;\n    font-size: small;\n    margin: 0px 8px;\n  } \n  \n  .tooltip:hover .tooltiptext {\n    visibility: visible;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
