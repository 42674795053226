import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getSubscriptionStatus,
  getUserData,
  submitProfileForm,
} from "./api_calls";

export default function Profile() {
  const { user } = useAuth0(); // user logged in
  const { userId } = useParams(); // user profile page
  const [canEdit, setCanEdit] = useState(false); // user is a member of an 'Active' lab group
  const [edit, setEdit] = useState(false); // edit mode
  const [isTheirProfile, setIsTheirProfile] = useState(false);

  const setEditSaveToggle = () => {
    setEdit(!edit);
  };

  let [fieldValues, setFieldValues] = useState({});
  let [userData, setUserData] = useState({});

  useEffect(() => {
    /**
     *   Check if user currently logged-in is a member of a lab group with an 'Active' subscription
     *   If so, give them permission to buy a sample
     */
    getSubscriptionStatus(user)
      .then((response) => {
        response.length
          ? response[0].subscription === "Active"
            ? setCanEdit(true)
            : setCanEdit(false)
          : setCanEdit(false);
      })
      .catch((err) => console.log(err));

    /**
     *   Check if logged-in user is on their profile page
     *   If so, give them the ability to edit their profile
     */
    getUserData(userId)
      .then((response) => {
        if (response.length) {
          setUserData(response[0]);
          setFieldValues(response[0]);
          if (userId === user.nickname) {
            setIsTheirProfile(true);
          }
        }
      })
      .catch((err) => console.log(err));
  }, [user, user.nickname, userId]);

  /**
   *  Form Submission
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    submitProfileForm(fieldValues);
    window.location.reload(true);
  };

  /**
   *  Editing is allowed on only three fields full_name, title, degree. The other fields are
   *  set when they are subscribed to a lab group
   */

  return (
    <>
      <form className="form-content" onSubmit={handleSubmit}>
        <div className="border border-solid m-3 pb-3 md:ml-10 md:mr-10 md:mt-5 md:p-3 md:pb-5 rounded bg-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)]">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 px-3 pt-2">
              <label className="text-gray-400">User Name</label>
              <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                {userId}
              </div>
            </div>
            <div className="w-full md:w-1/2 px-3 pt-2">
              <label className="text-gray-400">Email</label>
              <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                {userData.email ? userData.email : "-"}
              </div>
            </div>
            <div className="w-full md:w-1/2 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Full Name</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="full_name"
                    value={fieldValues.full_name}
                    placeholder={userData.full_name}
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        full_name: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Full Name</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {userData.full_name ? userData.full_name : "-"}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3 pt-2"></div>
            <div className="w-full md:w-1/2 px-3 pt-2">
              <label className="text-gray-400">Laboratory</label>
              <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                {userData.lab_name ? userData.lab_name : "-"}
              </div>
            </div>
            <div className="w-full md:w-1/2 px-3 pt-2">
              <label className="text-gray-400">CLIA #</label>
              <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                {userData.clia_number ? userData.clia_number : "-"}
              </div>
            </div>
            <div className="w-full md:w-1/2 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Title</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="title"
                    value={fieldValues.title}
                    placeholder={userData.title}
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        title: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Title</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                    {userData.title ? userData.title : "-"}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3 pt-2">
              {edit && canEdit ? (
                <div>
                  <label className="text-gray-400">Degree</label>
                  <input
                    type="text"
                    className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                    id="degree"
                    value={fieldValues.degree}
                    placeholder={userData.degree}
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        degree: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              ) : (
                <div>
                  <label className="text-gray-400">Degree</label>
                  <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-5 leading-tight">
                    {userData.degree ? userData.degree : "-"}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full">
              {canEdit && isTheirProfile ? (
                <div className="flex justify-center pt-4 pb-8">
                  {!edit && (
                    <button
                      type="button"
                      className="text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      onClick={setEditSaveToggle}
                    >
                      Edit
                    </button>
                  )}
                  {edit && (
                    <button
                      type="submit"
                      className="text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      Save
                    </button>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </form>
    </>
  );
} /*

/*
  Auth0 {user} object contents
*/
/*
nickname: jeri.dilts
name: jeri.dilts@proton.me
picture: https://s.gravatar.com/avatar/0dd1648dbe2c93a27f623849a3790e51?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fje.png
updated_at: 2024-04-17T15:06:37.961Z
email: jeri.dilts@proton.me
email_verified:
sub: auth0|66134d4687394172ec4a2a35 
*/
