/**
 *
 * API Calls
 *
 */


/**
 *  CORS Headers
 */
let headers = {
  "Access-Control-Request-Method": "POST",
  "Access-Control-Request-Headers": "Content-Type, Accept",
  Origin: "*",
  "Content-Type": "application/json",
};

export async function getSubscriptionStatus(props) {
  let email = props.email;

  let response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/getSubscriptionStatus`,
    {
      method: "POST",
      mode: `cors`,
      body: JSON.stringify({
        email: email,
      }),
      headers: headers,
    }
  );
  response = await response.json();
  return response;
}

export async function getUserData(props) {
  let userId = props;

  let response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getUser`, {
    method: "POST",
    mode: `cors`,
    body: JSON.stringify({
      userId: userId, // username -- Auth0's {user.nickname}
    }),
    headers: headers,
  });
  response = await response.json();
  return response;
}

export function submitProfileForm(props) {
  let fieldValues = props;

  fetch(`${process.env.REACT_APP_SERVER_URL}/updateUser`, {
    method: "POST",
    mode: `cors`,
    body: JSON.stringify({
      full_name: fieldValues.full_name,
      title: fieldValues.title,
      degree: fieldValues.degree,
      email: fieldValues.email,
    }),
    headers: headers,
  });
}

export async function checkIfUserOwnsSample(props) {
  let id = props; // CA_id

  let response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/checkIfUserOwnsSample`,
    {
      method: "POST",
      mode: `cors`,
      body: JSON.stringify({
        id: id, // CA_id
      }),
      headers: headers,
    }
  );
  response = await response.json();
  return response;
}



export function updateSample(props) {
  let fieldValues = props;
  let CA_id = props.CA_id;

  fetch(`${process.env.REACT_APP_SERVER_URL}/updateSample`, {
    method: "POST",
    mode: `cors`,
    body: JSON.stringify({
      CA_id: CA_id,
      lab_name: fieldValues.lab_name,
      clia_number: fieldValues.clia_number,
      accession_number: fieldValues.accession_number,
      panel: fieldValues.panel,
      organism_detected: fieldValues.organism_detected,
      CT_level: fieldValues.CT_level,
      collection_date: fieldValues.collection_date,
      collection_method: fieldValues.collection_method,
      extraction_date: fieldValues.extraction_date,
      extraction_method: fieldValues.extraction_method,
      extraction_tech: fieldValues.extraction_tech,
      pcr_tech: fieldValues.pcr_tech,
      instrument_used: fieldValues.instrument_used,
      alliquot_num: fieldValues.alliquot_num,
      notes: fieldValues.notes
    }),
    headers: headers,
  });
}
