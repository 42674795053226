import { useAuth0 } from "@auth0/auth0-react";

export default function OrangeLoginBtn() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/findSample",
      },
    });
  };

  return (
    <>
      {
        /* only show login button if user IS NOT logged in */
        !isAuthenticated && (
          <button
            type="button"
            className="text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            onClick={handleLogin}
          >
            Sign In
          </button>
        )
      }
    </>
  );
}