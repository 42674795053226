import MagicCheckbox from "../sampleDirectory/magicCheckbox";

export const columnDef = [
  {
    id: "select-col",
    header: ({ table }) => (
      <MagicCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()} //or getToggleAllPageRowsSelectedHandler
      />
    ),
    cell: ({ row }) => (
      <MagicCheckbox
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  },
  {
    accessorKey: "CA_id",
    header: "CA_ID",
  },
  {
    accessorKey: "accession_number",
    header: "Accession_#",
  },
  {
    accessorKey: "panel",
    header: "Panel",
  },
  {
    accessorKey: "CT_level",
    header: "CT_Level",
  },
  {
    accessorKey: "organism_detected",
    header: "Organism_Detected",
    cell: (info) => <i>{info.getValue()}</i>,
  },
  {
    accessorKey: "collection_date",
    header: "Collection_Date",
  },
  {
    accessorKey: "alliquot_num",
    header: "Alliquot_Num",
  },
  {
    accessorKey: "extraction_method",
    header: "Extraction_Method",
  },
  {
    accessorKey: "lab_name",
    header: "Laboratory",
  },
  {
    accessorKey: "user",
    header: "Sample_Registrant"
  }
];
