// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* change autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #e5e7eb inset !important; /* background */
    -webkit-text-fill-color: #374151; /* font-color */
}`, "",{"version":3,"sources":["webpack://./src/components/labPage/labPage.css"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;;;;IAII,uDAAuD,EAAE,eAAe;IACxE,gCAAgC,EAAE,eAAe;AACrD","sourcesContent":["/* change autocomplete styles in Chrome */\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover, \ninput:-webkit-autofill:focus, \ninput:-webkit-autofill:active{\n    -webkit-box-shadow: 0 0 0 30px #e5e7eb inset !important; /* background */\n    -webkit-text-fill-color: #374151; /* font-color */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
