import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import FormErrors from "../formErrors";
import "./labPage.css";
import Select from "react-select";
import chroma from "chroma-js";
import { useAuth0 } from "@auth0/auth0-react";

export default function LabPage() {
  const { clia_no } = useParams(); // grab clia# from url
  const { user } = useAuth0(); // user currently logged in
  const [edit, setEdit] = useState(false); // edit mode
  const [canEdit, setCanEdit] = useState(false); // EDIT button
  const [subscriptionDates, setSubscriptionDates] = useState({
    startDate: "",
    endDate: "End Date",
  });
  const setEditSaveToggle = () => {
    setEdit(!edit);
  };

  /*****
  
    Check if individual logged in can edit the lab form

  *****/

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/canUserEditLabForm`,
        {
          method: "POST",
          mode: `cors`,
          body: JSON.stringify({
            clia_no: clia_no,
            user: user.email,
          }),
          headers: {
            "Access-Control-Request-Method": "POST",
            "Access-Control-Request-Headers": "Content-Type, Accept",
            Origin: "*",
            "Content-Type": "application/json",
          },
        }
      );
      response = await response.json();
      if (response && response.length > 0) {
        setCanEdit(true);
      } else {
        setCanEdit(false);
      }
    }
    fetchMyAPI();
  }, [clia_no, user, canEdit]);

  /*****
  
    GET subscription dates of the lab to display

  *****/

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getSubscriptionDates`,
        {
          method: "POST",
          mode: `cors`,
          body: JSON.stringify({
            clia_no: clia_no,
          }),
          headers: {
            "Access-Control-Request-Method": "POST",
            "Access-Control-Request-Headers": "Content-Type, Accept",
            Origin: "*",
            "Content-Type": "application/json",
          },
        }
      );
      response = await response.json();
      const arr = response[0].dt.split(",");
      setSubscriptionDates({
        ...subscriptionDates,
        startDate: arr[0],
      });
    }
    fetchMyAPI();
    // eslint-disable-next-line
  }, [clia_no]);

  /***** 

    'GET' data to populate dropdowns

  *****/

  // get 'state' list from the api
  let [stateOptions, setStateOptions] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/states`, { mode: `cors` })
      .then((res) => res.json())
      .then((states) => {
        for (const key of states) {
          let obj = {
            value: key["state_abbr"],
            label: key["state_abbr"],
            type: "state",
          };
          setStateOptions((stateOptions) => [...stateOptions, obj]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // get 'panel' list from the api
  const [panelOptions, setPanelOptions] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/panels`, { mode: `cors` })
      .then((res) => res.json())
      .then((pan) => {
        for (const key of pan) {
          let obj = {
            value: key["panel"],
            label: key["label"],
            color: key["color"],
          };
          setPanelOptions((panelOptions) => [...panelOptions, obj]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  /*****
  
    GET LAB DATA - w/useParams()

  *****/
  const [lab, setLab] = useState({});
  const [fieldValues, setFieldValues] = useState({});
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getLab`, {
        method: "POST",
        mode: `cors`,
        body: JSON.stringify({
          clia_no: clia_no,
        }),
        headers: {
          "Access-Control-Request-Method": "POST",
          "Access-Control-Request-Headers": "Content-Type, Accept",
          Origin: "*",
          "Content-Type": "application/json",
        },
      });
      response = await response.json();
      //console.log(response[0]);
      //response[0].panels = response[0].panels.panels.join(', '); // clean up panels
      setLab(response[0]);
      setFieldValues(response[0]);
    }

    fetchMyAPI();
  }, [clia_no]);

  /*****
  
    STATES - these are the foundational states of this particular form

  *****/

  /* FIELD VALUES VALID? */
  let [fieldValuesValid, setFieldValuesValid] = useState({
    lab_name: false,
    street_address: false,
    city: false,
    state_abbr: false,
    zipcode: false,
    clia_number: false,
    panels: false,
    phone_no: false,
    website: true, // field isn't required. It can submit empty.
  });
  /* ERROR TEXT (IF ANY) */
  let [formErrors, setFormErrors] = useState({
    lab_name: "",
    street_address: "",
    city: "",
    state_abbr: "",
    zipcode: "",
    clia_number: "",
    panels: "",
    phone_no: "",
    website: "",
  });
  /* FORM VALID TO BE SUBMITTED? STATE */
  // eslint-disable-next-line
  let [formState, setFormState] = useState();

  /***** 

    FIELD VALIDATORS

  *****/

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "lab_name":
        fieldValuesValid.lab_name = value.length >= 3 && value.length <= 100;
        formErrors.lab_name = fieldValuesValid.lab_name ? "" : " Required";
        break;
      case "street_address":
        fieldValuesValid.street_address =
          value.length >= 5 && value.length <= 100;
        formErrors.street_address = fieldValuesValid.street_address
          ? ""
          : " Required";
        break;
      case "city":
        fieldValuesValid.city = value.length >= 3 && value.length <= 100;
        formErrors.city = fieldValuesValid.city ? "" : " Required";
        break;
      case "state":
        // option loaded from api. no validator required.
        break;
      case "zipcode":
        if (value === "") {
          fieldValuesValid.zipcode = false;
          formErrors.zipcode = " Required";
        } else if (value.length === 5) {
          fieldValuesValid.zipcode = true;
          formErrors.zipcode = "";
        } else if (value.length > 5) {
          fieldValuesValid.zipcode = false;
          formErrors.zipcode = "Too long";
        }
        break;
      case "clia_number":
        fieldValuesValid.clia_number = value.length === 10;
        formErrors.clia_number = fieldValuesValid.clia_number
          ? ""
          : " Required";
        break;
      case "panels":
        // option loaded from api. no validator required.
        break;
      case "phone_no":
        if (value === "") {
          formErrors.phone_no = " Required";
          fieldValuesValid.phone_no = false;
        } else {
          let pho = value
            .toLowerCase()
            .match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im);
          if (pho) {
            fieldValuesValid.phone_no = true;
            formErrors.phone_no = "";
          } else {
            fieldValuesValid.phone_no = false;
            formErrors.phone_no = "Incorrect phone number format";
          }
        }
        break;
      case "website":
        fieldValuesValid.website = /(^https:\/\/)|(^\s*$)/.test(value);
        formErrors.website = fieldValuesValid.website
          ? ""
          : " URL requried to begin with https:// ";
        break;
      default:
        break;
    }

    setFormErrors(formErrors);
    setFieldValuesValid(fieldValuesValid);
  };

  /*****
   
    MULTI-SELECT COLORED DROPDOWN
   
  *****/

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      lineHeight: "1.8rem",
      borderColor: "#fa5a39",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: data.color,
        color: "white",
      },
    }),
  };

  /*****
  
    HANDLE DROPDOWN STATE CHANGES

  *****/

  const [stateSelectedAbbrOption, setSelectedStateAbbr] = useState(null);
  // eslint-disable-next-line
  const [panelSelectedOptions, setPanelSelectedOptions] = useState(null);

  const handleChange = (item) => {
    if (item.type === "state") {
      setSelectedStateAbbr(item);
      setFieldValues({ ...fieldValues, state_abbr: item.value });
      setFieldValuesValid({ ...fieldValuesValid, state_abbr: true });
    } else {
      // panels
      setPanelSelectedOptions(item);
      let arr = { panels: [] };
      for (let i = 0; i < item.length; i++) {
        arr.panels.push(item[i].value);
      }
      setFieldValues({ ...fieldValues, panels: arr });
      setFieldValuesValid({ ...fieldValuesValid, panels: true });
    }
  };

  /*****
   
    Validtion handling for fields that didn't change in edit mode
  
  *****/
  function handleEditModeValidationFields() {
    for (const property in fieldValuesValid) {
      if (fieldValuesValid[property] === false) {
        //check if the field was edited, if not set to 'true'
        if (lab[property] === fieldValues[property]) {
          setFieldValuesValid({ ...(fieldValuesValid[property] = true) });
        }
      }
    }
  }

  /*****
   
    FORM SUBMISSION LOGIC
  
  *****/

  // on form submission...
  const handleSubmit = (event) => {
    event.preventDefault();

    console.log(lab);
    console.log(fieldValues);

    handleEditModeValidationFields();

    console.log(fieldValuesValid);

    formState =
      fieldValuesValid.lab_name &&
      fieldValuesValid.street_address &&
      fieldValuesValid.city &&
      fieldValuesValid.state_abbr &&
      fieldValuesValid.zipcode &&
      fieldValuesValid.clia_number &&
      fieldValuesValid.panels &&
      fieldValuesValid.phone_no &&
      fieldValuesValid.website;

    console.log(formState);
    //formState = false; // prevent submission while trying to fill states

    let panelString = formState ? JSON.stringify(fieldValues.panels) : null;

    if (formState) {
      fetch(`${process.env.REACT_APP_SERVER_URL}/updateLab`, {
        method: "POST",
        mode: `cors`,
        body: JSON.stringify({
          lab_name: fieldValues.lab_name,
          street_address: fieldValues.street_address,
          city: fieldValues.city,
          state_abbr: fieldValues.state_abbr,
          zipcode: fieldValues.zipcode,
          clia_number: fieldValues.clia_number,
          panels: panelString,
          phone_no: fieldValues.phone_no,
          website: fieldValues.website,
          user: user.email,
        }),
        headers: {
          "Access-Control-Request-Method": "POST",
          "Access-Control-Request-Headers": "Content-Type, Accept",
          Origin: "*",
          "Content-Type": "application/json",
        },
      })
        .then((resp) => {
          window.location.reload(true);
          console.log(resp.json);
          return resp.json();
        })
        .then(function (data) {
          console.log(data);
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  return (
    <>
      <div>
        <form className="form-content" onSubmit={handleSubmit}>
          <div className="border border-solid m-3 pb-3 md:ml-10 md:mr-10 md:mt-5 p-3 md:pb-5 rounded bg-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)]">
            <div className="flex flex-wrap">
              <div className="w-full text-xl">{lab.lab_name}</div>
              <div className="w-full text-sm mt-2 mb-5">
                Subscription: {subscriptionDates.startDate} -{" "}
                {subscriptionDates.endDate}
              </div>
              <div className="w-full md:w-3/4 px-3 pt-2">
                {edit && canEdit ? (
                  <div>
                    <label className="text-gray-400">Laboratory Name</label>
                    <input
                      type="text"
                      className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                      id="lab_name"
                      value={fieldValues.lab_name}
                      placeholder={lab.lab_name}
                      required
                      onChange={(e) => {
                        setFieldValues({
                          ...fieldValues,
                          lab_name: e.target.value,
                        });
                        validateField("lab_name", e.target.value);
                      }}
                    ></input>
                  </div>
                ) : (
                  <div>
                    <label className="text-gray-400">Laboratory Name</label>
                    <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                      {lab.lab_name}
                    </div>
                  </div>
                )}
                <div className="panel panel-default">
                  <FormErrors formErrors={formErrors} fieldName="lab_name" />
                </div>
              </div>
              <div className="w-full md:w-1/3 px-3 pt-2">
                {edit && canEdit ? (
                  <div>
                    <label className="text-gray-400">Street Address</label>
                    <input
                      type="text"
                      className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                      id="street_address"
                      value={fieldValues.street_address}
                      placeholder={lab.street_address}
                      required
                      onChange={(e) => {
                        setFieldValues({
                          ...fieldValues,
                          street_address: e.target.value,
                        });
                        validateField("street_address", e.target.value);
                      }}
                    ></input>
                  </div>
                ) : (
                  <div>
                    <label className="text-gray-400">Street Address</label>
                    <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                      {lab.street_address}
                    </div>
                  </div>
                )}
                <div className="panel panel-default">
                  <FormErrors
                    formErrors={formErrors}
                    fieldName="street_address"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/3 px-3 pt-2">
                {edit && canEdit ? (
                  <div>
                    <label className="text-gray-400">City</label>
                    <input
                      type="text"
                      className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                      id="city"
                      value={fieldValues.city}
                      placeholder={lab.city}
                      required
                      onChange={(e) => {
                        setFieldValues({
                          ...fieldValues,
                          city: e.target.value,
                        });
                        validateField("city", e.target.value);
                      }}
                    ></input>
                  </div>
                ) : (
                  <div>
                    <label className="text-gray-400">City</label>
                    <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                      {lab.city}
                    </div>
                  </div>
                )}
                <div className="panel panel-default">
                  <FormErrors formErrors={formErrors} fieldName="city" />
                </div>
              </div>
              <div className="w-full md:w-1/6 px-3 pt-2">
                {edit && canEdit ? (
                  <div>
                    <label className="text-gray-400">State</label>
                    <Select
                      value={stateSelectedAbbrOption}
                      onChange={handleChange}
                      options={stateOptions}
                      className="border border-mexican-orange rounded"
                      placeholder={lab.state_abbr}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          //backgroundColor: "#e5e7eb",
                          lineHeight: "2rem",
                          borderColor: state.isFocused ? "none" : "none",
                          borderWidth: 0,
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          neutral50: "#374151", // placeholder color
                        },
                      })}
                    />
                  </div>
                ) : (
                  <div>
                    <label className="text-gray-400">State</label>
                    <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                      {lab.state_abbr}
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full md:w-1/6 px-3 pt-2">
                {edit && canEdit ? (
                  <div>
                    <label className="text-gray-400">Zipcode</label>
                    <input
                      type="text"
                      className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                      id="zipcode"
                      value={fieldValues.zipcode}
                      placeholder={lab.zipcode}
                      required
                      onChange={(e) => {
                        setFieldValues({
                          ...fieldValues,
                          zipcode: e.target.value,
                        });
                        validateField("zipcode", e.target.value);
                      }}
                    ></input>
                  </div>
                ) : (
                  <div>
                    <label className="text-gray-400">Zipcode</label>
                    <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                      {lab.zipcode}
                    </div>
                  </div>
                )}
                <div className="panel panel-default">
                  <FormErrors formErrors={formErrors} fieldName="zipcode" />
                </div>
              </div>
              <div className="w-full md:w-1/4 px-3 pt-2">
                {edit && canEdit ? (
                  // user NOT allowed to edit CLIA# field
                  <div>
                    <label className="text-gray-400">CLIA#</label>
                    <input
                      type="text"
                      className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight"
                      id="clia_number"
                      value={fieldValues.clia_number}
                      placeholder={lab.clia_number}
                      required
                      readOnly
                    ></input>
                  </div>
                ) : (
                  <div>
                    <label className="text-gray-400">CLIA#</label>
                    <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                      {lab.clia_number}
                    </div>
                  </div>
                )}
                <div className="panel panel-default">
                  <FormErrors formErrors={formErrors} fieldName="clia_number" />
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3 pt-2">
                {edit && canEdit ? (
                  <div>
                    <label className="text-gray-400">Panels Offered</label>
                    <Select
                      closeMenuOnSelect={false}
                      onChange={handleChange}
                      //defaultValue={{label: JSON.stringify(lab?.panels), value: JSON.stringify(lab?.panels)}}
                      // need to work on giving default values color
                      isMulti
                      options={panelOptions}
                      styles={colourStyles}
                      allowSelectAll={true}
                    />
                  </div>
                ) : (
                  <div>
                    <label className="text-gray-400">Panels Offered</label>
                    <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                      {JSON.stringify(lab.panels)}
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full md:w-1/4 px-3 pt-2"></div>
              <div className="w-full md:w-1/4 px-3 pt-2">
                {edit && canEdit ? (
                  <div>
                    <label className="text-gray-400">Phone Number</label>
                    <input
                      type="text"
                      className="appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                      id="phone_no"
                      value={fieldValues.phone_no}
                      placeholder={lab.phone_no}
                      required
                      onChange={(e) => {
                        setFieldValues({
                          ...fieldValues,
                          phone_no: e.target.value,
                        });
                        validateField("phone_no", e.target.value);
                      }}
                    ></input>
                  </div>
                ) : (
                  <div>
                    <label className="text-gray-400">Phone Number</label>
                    <div className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                      {lab.phone_no}
                    </div>
                  </div>
                )}
                <div className="panel panel-default">
                  <FormErrors formErrors={formErrors} fieldName="phone_no" />
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3 pt-2 pb-5">
                {edit && canEdit ? (
                  <div>
                    <label className="text-gray-400">Website</label>
                    <input
                      type="text"
                      className="overflow-hidden appearance-none placeholder:text-gray-700 block w-full bg-white text-gray-700 border border-mexican-orange rounded py-3 px-4 leading-tight"
                      id="website"
                      value={fieldValues.website}
                      placeholder={lab.website}
                      onChange={(e) => {
                        setFieldValues({
                          ...fieldValues,
                          website: e.target.value,
                        });
                        validateField("website", e.target.value);
                      }}
                    ></input>
                  </div>
                ) : (
                  <div>
                    <label className="text-gray-400">Website</label>
                    <div className="overflow-hidden appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight">
                      {lab.website ? lab.website : "-"}
                    </div>
                  </div>
                )}
                <div className="panel panel-default">
                  <FormErrors formErrors={formErrors} fieldName="website" />
                </div>
              </div>
              {canEdit ? (
                <div className="w-full md:w-1/4">
                  <div className="flex justify-center pt-8">
                    {!edit && (
                      <button
                        type="button"
                        className="text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        onClick={setEditSaveToggle}
                      >
                        Edit
                      </button>
                    )}
                    {edit && (
                      <button
                        type="submit"
                        className="text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        /*onClick={setEditSaveToggle}*/
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
