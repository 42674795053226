import { Bars3Icon } from "@heroicons/react/24/outline";
import LogoutIconBtn from "./buttons/logoutIconBtn";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

export default function Header(props) {
  const { isAuthenticated } = useAuth0();
  let location = useLocation();
  let rootPage = location.pathname === "/" ? true : false;

  return (
    <>
      {!isAuthenticated && (
        <div
          id="WhiteHeaderBar"
          className="w-full font-['Montserrat'] py-3 md:py-7 sticky top-0 z-20"
        ></div>
      )}

      {isAuthenticated && (
        <div
          id="WhiteHeaderBar"
          className={
            "w-full " +
            (!rootPage ? " shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)]" : "") +
            " font-['Montserrat'] sticky top-0 z-20"
          }
        >
          <div
            className={
              "flex flex-row flex-1 justify-between py-3 items-center px-3 md:px-7" +
              (props.nT ? " bg-black opacity-70" : " bg-white")
            }
          >
            <div className="flex flex-row items-center gap-2">
              <button
                type="button"
                onClick={() => props.setNT()}
                className="hover:bg-lite-gray hover:rounded-full rounded-4 p-3 "
              >
                <Bars3Icon className="h-6 w-6" />
              </button>
              {!rootPage && (
                <div className="font-medium text-xl text-dark-gray">
                  Central Alliant
                </div>
              )}
            </div>
            <LogoutIconBtn />
          </div>
          {/* gradient line decoration logic  - only show on non-root pages*/}
          {!rootPage && (
            <div
              className={
                "flex flex-col" +
                (props.nT
                  ? " bg-black opacity-70"
                  : " bg-gradient-to-r from-cyan-200 to-amber-100 items-start bg-cover bg-center bg-50%_50% bg-no-repeat") +
                " mx-auto pl-7 pr-7 md:pl-20 md:pr-20 pt-3 "
              }
            ></div>
          )}
        </div>
      )}
    </>
  );
}
