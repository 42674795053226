import { withAuthenticationRequired } from "@auth0/auth0-react";
import { PuffLoader } from "react-spinners";

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="h-screen flex items-center justify-center">
        <PuffLoader />
      </div>
    ),
  });

  return <Component />;
};
