import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { getSubscriptionStatus } from "./api_calls";

export default function ImportFromFile() {
  const { user } = useAuth0(); // user currently logged-in
  const navigate = useNavigate();
  const headersCSVFile = `${process.env.REACT_APP_CLIENT_URL}/headers.csv`;
  const [subStatus, setSubStatus] = useState(false);

  /**
   *  Check if user currently logged-in is a member of a lab group with an 'Active' subscription
   */
  useEffect(() => {
    getSubscriptionStatus(user)
      .then((response) => {
        response.length
          ? response[0].subscription === "Active"
            ? setSubStatus(true)
            : setSubStatus(false)
          : setSubStatus(false);
      })
      .catch((err) => console.log(err));
  }, [user]);

  // download csv with headers
  const downloadFileAtURL = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  // upload csv with headers and data
  const [file, setFile] = useState();
  const upload = () => {
    var data = new FormData();
    data.append("user", user.email);
    data.append("file", file);

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/upload`, data)
      .then((res) => {
        navigate("/findSample");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="border border-solid ml-5 mr-5 mt-3 p-3 md:ml-10 md:mr-10 md:mt-5 mb-5 md:p-3 rounded bg-gradient-to-r from-cyan-200 to-amber-100 shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)]">
        <div className="border border-solid md:m-3 pb-3 md:ml-10 md:mr-10 md:mt-5 p-3 md:pb-5 rounded bg-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)]">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 pl-3 text-2xl pb-2 md:pb-5 pt-3">
              Multiple Sample Import Tool
            </div>
            <div className="w-full md:w-1/2 pl-3 pb-5 md:pb-3"></div>
            <div className="w-full md:w-3/4 pl-3 ">
              This tool allows you to import multiple samples at one time with
              our input CSV (comma seperated value) file format. First, click on
              the 'Download CSV' button. This button downloads a empty csv file
              with the appropriate headers for uploading your data. Each header
              is described in detail below. There are 15 columns in all. The
              import CSV file should have a line per organism.
            </div>
            <div className="w-full md:w-1/4 pl-3 pt-5 ">
              <button
                type="submit"
                className="text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-[0px_1px_1px_0px_rgba(0,_0,_0,_0.1)]"
                onClick={() => {
                  downloadFileAtURL(headersCSVFile);
                }}
              >
                Download CSV
              </button>
            </div>

            <div className="w-full md:w-1/2 pl-3 pt-3 "></div>
            <div className="w-full pl-3 pt-5">
              <div className="md:grid md:grid-cols-3">
                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  accession_number
                </div>
                <div className="text-base">
                  The accession number designated by your lab
                </div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  panel
                </div>
                <div className="text-base">
                  wound, gi, uti, nail, gbs, vaginal, gas, covid+, respitory,
                  sti, hpv
                </div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  CT_level
                </div>
                <div className="text-red-600 text-base">
                  cycles? (max/min value?)
                </div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  organism_detected
                </div>
                <div className="text-base">
                  <i>genus species</i>
                </div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  collection_date
                </div>
                <div className="text-base">mm/dd/yyyy</div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  collection_method
                </div>
                <div className="text-base">
                  e.g. Wound Swab, GI Solid Sample, Urine, Vaginal Swab
                </div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  alliquot_num
                </div>
                <div className="text-base">
                  Number of alliquots per sample
                </div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  extraction_date
                </div>
                <div className="text-base">mm/dd/yyyy</div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  extraction_method
                </div>
                <div className="text-base">
                  e.g. PCRopsis, DTPM-column, DTPM-magnetic processor, D2P
                </div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  lab_name
                </div>
                <div className="text-base">
                  Lab name as registered on the platform
                </div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  clia_number
                </div>
                <div className="text-base">
                  CLIA# registered on the platform
                </div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  extraction_tech
                </div>
                <div className="text-base">FirstName LastName</div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  pcr_tech
                </div>
                <div className="text-base">FirstName LastName</div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  instrument_used
                </div>
                <div className="text-base">
                  e.g. Biorad CFX384, Airclean600, King Fisher Flex Extraction,
                  Thermo Fisher Quant Studio
                </div>
                <div></div>

                <div className="text-mexican-orange flex md:justify-end font-bold pr-10">
                  notes
                </div>
                <div className="text-base">
                  Any information about the sample not given above
                </div>
                <div></div>
              </div>
            </div>
            {subStatus ? (
              <div>
                <div className="flex flex-wrap">
                  <div className="w-full mt-10 ml-5">
                    <input
                      type="file"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </div>
                  <div className="w-full mt-5 mb-5 ml-5">
                    <button
                      type="button"
                      className="text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-[0px_1px_1px_0px_rgba(0,_0,_0,_0.1)]"
                      onClick={upload}
                    >
                      Upload CSV
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
