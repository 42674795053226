export default function Review() {
  return (
    <>
      <div className="border border-solid m-3 p-2 pb-3 md:w-full md:w-1/2 md:mr-10 md:p-3 md:pb-5 rounded bg-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)]">
        <div className="w-full md:w-1/2 text-xl mb-5">Review</div>
        <div className="ml-5">
          <div>
            <span className="font-bold">
              1 Year Subscription to Central Alliant &nbsp;&nbsp;&nbsp;
            </span>
            <span>(startDate - endDate)</span>
          </div>
          <div className="font-bold">
            <span>Amount: &nbsp;</span>
            <span className="text-mexican-orange">$100.00</span>
          </div>
          <br></br>
          <div className="text-lg">Point of Contact: &nbsp;</div>
          <br></br>
          <div className="text-lg">Billing Information</div>
          <div className="ml-5 pt-2">
            <div>Full Name:</div>
            <div>Credit Card Number: XXXX-XXXX-XXXX-####</div>
            <div>Exp.Date: MM/YYYY CVV: ### </div>
            <div>Street Address</div>
            <div>City: State: Zipcode:</div>
          </div>
          <br></br>
          <div className="text-lg">Shipping Information</div>
          <div className="ml-5 pt-2">
            <div>?</div>
          </div>
        </div>
      </div>
    </>
  );
}
