export function TableControls(props) {
  const tableInstance = props.tableInstance;

  /**
   * using Tanstack Tables 0 page indexing,
   * but displaying pages starting at 1 instead of 0
   */

  return (
    <>
      <div className="flex justify-end">
        <div className="flex flex-row mt-4 align-right">
          <button
            type="button"
            onClick={() => tableInstance.setPageIndex(0)}
            disabled={!tableInstance.getCanPreviousPage()}
            className="mr-2 text-white bg-orange-500 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-[0px_1px_1px_0px_rgba(0,_0,_0,_0.1)]"
          >
            {"<<"}
          </button>
          <button
            type="button"
            onClick={() => tableInstance.previousPage()}
            disabled={!tableInstance.getCanPreviousPage()}
            className="mr-2 text-white bg-orange-500 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-[0px_1px_1px_0px_rgba(0,_0,_0,_0.1)]"
          >
            Previous Page
          </button>
          <button
            type="button"
            onClick={() => tableInstance.nextPage()}
            disabled={!tableInstance.getCanNextPage()}
            className="mr-2 text-white bg-orange-500 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-[0px_1px_1px_0px_rgba(0,_0,_0,_0.1)]"
          >
            Next Page
          </button>
          <button
            type="button"
            onClick={() =>
              tableInstance.setPageIndex(tableInstance.getPageCount() - 1)
            }
            disabled={!tableInstance.getCanNextPage()}
            className="text-white bg-orange-500 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-[0px_1px_1px_0px_rgba(0,_0,_0,_0.1)]"
          >
            {">>"}
          </button>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="grid grid-cols-2 mt-3 md:w-1/4 pr-3">
          <div className="flex justify-end pb-1">Page: </div>
          <div className="flex justify-center">
            {(tableInstance.getState().pagination.pageIndex) +1 }
          </div>
          <div className="flex justify-end pb-1">Total Pages: </div>
          <div className="flex justify-center">
            {tableInstance.getPageCount()}
          </div>
          <div className="flex justify-end">
            <label htmlFor="page_jump">
              Jump to Page:
            </label>
          </div>
          <div className="flex justify-end pb-0.5">
            <input
              id="page_jump"
              type="number"
              defaultValue={1}
              className="appearance-none block w-20 text-gray-700 border rounded py-0.5 px-1 leading-tight outline-none bg-white border-gray-500"
              onChange={(e) => {
                if (e.target.value > 0) {
                  tableInstance.setPageIndex((e.target.value-1));
                }
              }}
            />
          </div>
          <div>
            <label htmlFor="page_size" className="flex justify-end">
              Page Size:
            </label>
          </div>
          <div className="flex justify-end">
            <select
              id="page-size"
              value={tableInstance.getState().pagination.pageSize}
              onChange={(e) => tableInstance.setPageSize(e.target.value)}
              className="appearance-none block w-20 mb-5 text-gray-700 border rounded py-0.5 px-1 leading-tight outline-none bg-white border-gray-500"
            >
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
}
