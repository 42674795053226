import React from "react";
import { useEffect, useState } from "react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { columnDef } from "./columns";
import "./table.css";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { TableControls } from "../TableControls";
import { useNavigate } from "react-router-dom";

export default function FindSampleTable() {
  let navigate = useNavigate();

  // get 'sample' list from the api
  const [samples, setSamples] = useState([]);
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(`${process.env.REACT_APP_SERVER_URL}/samples`);
      response = await response.json();
      setSamples(response);
    }

    fetchMyAPI();
  }, []);

  const finalData = React.useMemo(() => samples, [samples]);
  const finalCol = React.useMemo(() => columnDef, []);

  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 1, //initial page index -- can't get this to work
    pageSize: 15, //default page size
  });

  // table state - with bindings
  const tableInstance = useReactTable({
    columns: finalCol,
    data: finalData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filtering,
      rowSelection: rowSelection,
      pagination: pagination,
    },
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true, //row => row.original. 3:03 video conditional select
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    onPaginationChange: setPagination,
  });

  function openRow(rowData) {
    //console.log(rowData);
    navigate(`/sampleInfo/${rowData.CA_id}`);
  }

  return (
    <div>
      <div className="border border-solid m-3 pb-3 md:ml-10 md:mr-10 md:mt-5 p-3 md:pb-5 rounded bg-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)]">
        <div className="text-xl mb-5">Sample Finder Tool</div>

        {/* filter */}
        <div className="flex flex-row inputContainer appearance-none block w-full md:w-1/3 mb-5 text-gray-700 border rounded py-2 px-4 leading-tight outline-none bg-white border-gray-500">
          <span className="text-left">
            <MagnifyingGlassIcon className="h-6 w-6" />
          </span>
          <span>
            <input
              type="text"
              value={filtering}
              onChange={(e) => setFiltering(e.target.value)}
              autoComplete="off"
              className="mt-1 ml-3 outline-none"
            />
          </span>
        </div>
        <div style={{ maxWidth: "100%", overflowX: "auto" }}>
          <table>
            <thead>
              {tableInstance.getHeaderGroups().map((headerEl) => {
                return (
                  <tr key={headerEl.id}>
                    {
                      // header
                      headerEl.headers.map((columnEl) => {
                        return (
                          <th
                            key={columnEl.id}
                            colSpan={columnEl.colSpan}
                            onClick={columnEl.column.getToggleSortingHandler()}
                            className="pt-2 pb-2 bg-orange-500 text-white pr-2 pl-2"
                          >
                            <div className="flex flex-row items-center justify-center">
                              <span className="ml-2">
                                {flexRender(
                                  columnEl.column.columnDef.header,
                                  columnEl.getContext()
                                )}
                              </span>
                              {
                                {
                                  asc: "",
                                  desc: "",
                                }[columnEl.column.getIsSorted() ?? null]
                              }
                            </div>
                          </th>
                        );
                      })
                    }
                  </tr>
                );
              })}
            </thead>
            <tbody>
              {tableInstance.getRowModel().rows.map((rowEl) => {
                return (
                  <tr key={rowEl.id} onClick={() => openRow(rowEl.original)}>
                    {
                      // data
                      rowEl.getVisibleCells().map((cellEl) => {
                        return (
                          <td
                            key={cellEl.id}
                            className="text-center pt-1 pb-1 pl-1 pr-1"
                          >
                            {flexRender(
                              cellEl.column.columnDef.cell,
                              cellEl.getContext()
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/*<div>{console.log(tableInstance.getSelectedRowModel())}</div>*/}
        </div>
        <TableControls tableInstance={tableInstance} />
      </div>
    </div>
  );
}
