import LoginBtn from "./buttons/orangeLoginBtn";

export default function welcome() {
  return (
    <>
      <div>
        <div className="flex flex-col shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)] bg-gradient-to-r from-cyan-200 to-amber-100 items-start bg-cover bg-center bg-50%_50% bg-no-repeat mx-auto pl-7 pr-7 md:pl-20 md:pr-20 pt-9">
          <div className="text-xl md:text-3xl text-[#111111] font-light">
            Welcome to
          </div>
          <div className="text-4xl md:text-6xl text-dark-gray font-bold mb-2">
            Central Alliant
          </div>
          <div className="text-xl md:text-2xl text-black font-light mb-9">
            Alliant Lab Consulting's sample data collection hub
          </div>
          <div className="mb-9">
            <LoginBtn />
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-center justify-center mt-10">
          <div className="text-xl text-black mb-4">One Website,&nbsp;</div>
          <div className="text-xl text-mexican-orange font-bold mb-4">
            All the Data
          </div>
        </div>
        <div className="flex flex-col items-center text-black text-sm md:text-base font-normal">
          <div className="mt-5 md:mt-8">
            To find more about our services, please visit
          </div>
          <div>
            <a
              href="https://alliantlabconsulting.com"
              target="_blank"
              rel="noreferrer"
              className="font-bold text-mexican-orange hover:underline"
            >
              alliantlabconsulting.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
