// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  text-align: center;
  margin: 2rem 0 4rem 0;
}

.accordion {
  margin-left: 15px;
  margin-right: 20px;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #d8feff;
  font: 300;
}

.front-of-the-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.icon {
  margin-right: 10px;
}

.accordion-title:hover {
  background-color: #b2f8fa;
}

.accordion-title {
  padding: 0.75rem;
}
.accordion-content {
  padding: 1rem;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }
  .accordion {
    width: 90%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/accordion/accordion.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,yBAAyB;EACzB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":["h1 {\n  text-align: center;\n  margin: 2rem 0 4rem 0;\n}\n\n.accordion {\n  margin-left: 15px;\n  margin-right: 20px;\n}\n\n.accordion-title {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  cursor: pointer;\n  background-color: #d8feff;\n  font: 300;\n}\n\n.front-of-the-row {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n}\n\n.icon {\n  margin-right: 10px;\n}\n\n.accordion-title:hover {\n  background-color: #b2f8fa;\n}\n\n.accordion-title {\n  padding: 0.75rem;\n}\n.accordion-content {\n  padding: 1rem;\n}\n\n@media screen and (max-width: 700px) {\n  body {\n    font-size: 18px;\n  }\n  .accordion {\n    width: 90%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
