// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  /*font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;*/
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid #ddd;
  /*padding: 8px;*/
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

/*
table th, tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
}
*/
`, "",{"version":3,"sources":["webpack://./src/components/sampleDirectory/table.css"],"names":[],"mappings":"AAAA;EACE,6DAA6D;EAC7D,yBAAyB;EACzB,WAAW;AACb;;AAEA;;EAEE,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;;;;;;;CAQC","sourcesContent":["table {\n  /*font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;*/\n  border-collapse: collapse;\n  width: 100%;\n}\n\ntable td,\ntable th {\n  border: 1px solid #ddd;\n  /*padding: 8px;*/\n}\n\ntable tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\ntable tr:hover {\n  background-color: #ddd;\n}\n\n/*\ntable th, tfoot td {\n  padding-top: 12px;\n  padding-bottom: 12px;\n  text-align: center;\n  background-color: #4caf50;\n  color: white;\n}\n*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
