import {
  BeakerIcon,
  BugAntIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  UserIcon,
  XMarkIcon,
  UserGroupIcon,
  PencilSquareIcon,
  EnvelopeIcon,
  CodeBracketIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function SidebarDrawer(props) {
  const { user } = useAuth0();
  return (
    <>
      <div className="fixed w-screen h-screen bg-black opacity-70 z-30"></div>
      <div className="fixed top-0 left-0 z-40 bg-white overflow-y-auto h-screen w-full md:w-64 z-40">
        <button
          type="button"
          onClick={() => props.setNT()}
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 end-2.5 inline-flex items-center"
        >
          <XMarkIcon className="h-6 w-6" />
          <span className="sr-only">Close menu</span>
        </button>

        <ul className="flex flex-col py-3">
          <li className="px-5">
            <div className="flex flex-row items-center h-8">
              <div className="text-base md:text-sm font-black tracking-wide">
                Logged in as
              </div>
            </div>
          </li>

          <li onClick={() => props.setNT()}>
            <Link
              to={"/profile/" + user.nickname}
              className="relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-orange-500 pr-6"
            >
              <span className="inline-flex justify-center items-center ml-8">
                <UserIcon className="h-6 w-6 text-mexican-orange" />
              </span>
              <span className="ml-2 text-base md:text-sm tracking-wide truncate font-bold text-mexican-orange">
                {user.nickname}
              </span>
            </Link>
          </li>

          {/* about label */}
          <li className="px-5">
            <div className="flex flex-row items-center h-8">
              <div className="text-base md:text-sm font-black tracking-wide">
                About
              </div>
            </div>
          </li>

          {/* services */}
          <li onClick={() => props.setNT()}>
            <Link
              to="/services"
              className="relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-orange-500 pr-6"
            >
              <span className="inline-flex justify-center items-center ml-8">
                <BeakerIcon className="h-6 w-6" />
              </span>
              <span className="ml-2 text-base md:text-sm tracking-wide truncate">
                Services
              </span>
            </Link>
          </li>

          {/* contact us */}
          <li onClick={() => props.setNT()}>
            <Link
              to="/contact_us"
              className="relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-orange-500 pr-6"
            >
              <span className="inline-flex justify-center items-center ml-8">
                <EnvelopeIcon className="h-6 w-6" />
              </span>
              <span className="ml-2 text-base md:text-sm tracking-wide truncate">
                Contact Us
              </span>
            </Link>
          </li>

          {/* menu label */}
          <li className="px-5">
            <div className="flex flex-row items-center h-8">
              <div className="text-base md:text-sm font-black tracking-wide">
                Menu
              </div>
            </div>
          </li>

          {/* register lab */}
          <li onClick={() => props.setNT()}>
            <Link
              to="/registerLab"
              className="relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-orange-500 pr-6"
            >
              <span className="inline-flex justify-center items-center ml-8">
                <PencilSquareIcon className="h-6 w-6" />
              </span>
              <span className="ml-2 text-base md:text-sm tracking-wide truncate">
                Register Lab
              </span>
            </Link>
          </li>

          {/* lab directory */}
          <li onClick={() => props.setNT()}>
            <Link
              to="/labDirectory"
              className="relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-orange-500 pr-6"
            >
              <span className="inline-flex justify-center items-center ml-8">
                <UserGroupIcon className="h-6 w-6" />
              </span>
              <span className="ml-2 text-base md:text-sm tracking-wide truncate">
                Lab Directory
              </span>
            </Link>
          </li>

          {/* find sample */}
          <li onClick={() => props.setNT()}>
            <Link
              to="/findSample"
              className="relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-orange-500 pr-6"
            >
              <span className="inline-flex justify-center items-center ml-8">
                <MagnifyingGlassIcon className="h-6 w-6" />
              </span>
              <span className="ml-2 text-base md:text-sm tracking-wide truncate">
                Find Sample
              </span>
            </Link>
          </li>

          {/* import samples from file */}
          <li onClick={() => props.setNT()}>
            <Link
              to="/importSamplesFromFile"
              className="relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-orange-500 pr-6"
            >
              <span className="inline-flex justify-center items-center ml-8">
                <PlusIcon className="h-6 w-6" />
              </span>
              <span className="ml-2 text-base md:text-sm tracking-wide truncate">
                Import Sample File
              </span>
            </Link>
          </li>

          {/* tools label */}
          <li className="px-5">
            <div className="flex flex-row items-center h-8">
              <div className="text-base md:text-sm font-black tracking-wide">
                Tools
              </div>
            </div>
          </li>

          {/* report bugs */}
          <li onClick={() => props.setNT()}>
            <Link
              to="#"
              onClick={(event) => event.preventDefault()}
              className="relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-orange-500 pr-6"
            >
              <span className="inline-flex justify-center items-center ml-8">
                <BugAntIcon className="h-6 w-6" />
              </span>
              <span className="ml-2 text-base md:text-sm tracking-wide truncate">
                Report a Bug
              </span>
            </Link>
          </li>

          {/* versioning */}
          <li className="px-5">
            <div className="flex flex-row items-center h-8">
              <div className="text-base md:text-sm font-black tracking-wide">
                Version
              </div>
            </div>
          </li>
          <li onClick={() => props.setNT()}>
            <Link
              to="#"
              onClick={(event) => event.preventDefault()}
              className="relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-orange-500 pr-6"
            >
              <span className="inline-flex justify-center items-center ml-8">
                <CodeBracketIcon className="h-6 w-6" />
              </span>
              <span className="ml-2 text-base md:text-sm tracking-wide truncate">
                {process.env.REACT_APP_VERSION}
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
