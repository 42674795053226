import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export default function ContactUs() {
  const form = useRef();

  let [formState, setFormState] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setFormState(true);
    const delay = 5000; // in milliseconds
    setTimeout(() => {
      window.location.reload(true);
    }, delay);

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_CONTACT_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail} id="emailForm">
        <div className="border border-solid m-3 pb-3 md:ml-10 md:mr-10 md:mt-5 md:p-3 md:pb-5 rounded bg-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)]">
          <div className="grid grid-cols-1">
            <div className="w-full md:w-1/2 text-xl md:mb-5 p-3">Contact Us</div>
            <p className="mb-3 ml-3">
              If you have any questions about Central Alliant BioBank please
              send us a message.
            </p>
            <div className="w-full md:w-1/2 px-3 pt-2">
              <label>Full Name</label>
              <input
                type="text"
                name="user_name"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Name"
                required
              />
            </div>
            <div className="w-full md:w-1/2 px-3 pt-2">
              <label>Phone Number</label>
              <input
                type="text"
                name="phone"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Phone Number"
                required
              />
            </div>
            <div className="w-full md:w-1/2 px-3 pt-2">
              <label>Email</label>
              <input
                type="email"
                name="email"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Email"
                required
              />
            </div>
            <div className="w-full md:w-1/2 px-3 pt-2">
              <label>Message</label>
              <textarea
                rows="3"
                type="text"
                name="message"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Message"
                required
              />
            </div>
            <div className="w-full md:w-1/2 px-3 pt-4">
              {formState ? (
                <div className="text-center pt-2 bg-[#ccfbf1] rounded h-11 shadow-[0px_1px_1px_0px_rgba(0,_0,_0,_0.10)]">
                  Success! Thank you for the message.
                </div>
              ) : (
                <button
                  type="submit"
                  className="text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-[0px_1px_1px_0px_rgba(0,_0,_0,_0.1)]"
                >
                  Submit Message
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
