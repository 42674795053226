import { useAuth0 } from "@auth0/auth0-react";
import { ArrowRightStartOnRectangleIcon } from "@heroicons/react/24/outline";
import "../tooltip/tooltip.css";

export default function LogoutIconBtn() {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <>
      <div>
        <button type="button" className="tooltip pl-3" onClick={handleLogout}>
          <ArrowRightStartOnRectangleIcon className="h-6 w-6" />
          <span className="tooltiptext">Signout</span>
        </button>
      </div>
    </>
  );
}
