import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./components/header.js";
import Welcome from "./components/welcome";
import SidebarDrawer from "./components/sidebarDrawer";
import Services from "./components/servicesPage/services.js";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationGuard } from "./auth/authentication-guard.js";
import LabRegistration from "./components/labRegistrationPage/labRegistration.js";
import userProfile from "./components/profile.js";
import ImportFromFile from "./components/importFromFile.js";
import LabDirTable from "./components/labDirectory/labDirTable.js";
import FindSampleTable from "./components/sampleDirectory/findSampleTable.js";
import SampleInfoPage from "./components/samplePage/samplePage.js";
import ContactUs from "./components/contactUs.js";
import LabPage from "./components/labPage/labPage.js";
import { PuffLoader } from "react-spinners";

export default function App() {
  const { isLoading, error, isAuthenticated } = useAuth0();
  let [navToggle, setNavToggle] = useState(false);
  function setNT() {
    setNavToggle(!navToggle);
  }

  return (
    <>
      <Header setNT={setNT} nT={navToggle} />
      {navToggle && isAuthenticated && <SidebarDrawer setNT={setNT} />}
      {error && <p>Authentication Error</p>}
      {!error && isLoading && (
        <div className="h-screen flex items-center justify-center">
          <PuffLoader />
        </div>
      )}
      {/* takes care of page flicker - put spinner component here */}
      {!error && !isLoading && (
        <Routes>
          <Route exact path="/" element={<Welcome />} />
          <Route
            path="/services"
            element={<AuthenticationGuard component={Services} />}
          />
          <Route
            path="/registerLab"
            element={<AuthenticationGuard component={LabRegistration} />}
          />
          <Route
            path="/profile/:userId"
            element={<AuthenticationGuard component={userProfile} />}
          />
          <Route
            path="/importSamplesFromFile"
            element={<AuthenticationGuard component={ImportFromFile} />}
          />
          <Route
            path="/labDirectory"
            element={<AuthenticationGuard component={LabDirTable} />}
          />
          <Route
            path="/labInfo/:clia_no"
            element={<AuthenticationGuard component={LabPage} />}
          />
          <Route
            path="/findSample"
            element={<AuthenticationGuard component={FindSampleTable} />}
          />
          <Route
            path="/sampleInfo/:id" /* our designated platform id. not the id/accession# given by the user */
            element={<AuthenticationGuard component={SampleInfoPage} />}
          />
          <Route
            path="/contact_us"
            element={<AuthenticationGuard component={ContactUs} />}
          />
        </Routes>
      )}
    </>
  );
}
