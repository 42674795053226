// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 600px)  {
    .mobile-break { display: none; }
}`, "",{"version":3,"sources":["webpack://./src/components/servicesPage/services.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,aAAa,EAAE;AACnC","sourcesContent":["@media screen and (max-width: 600px)  {\n    .mobile-break { display: none; }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
