import "./accordion.css";
import { useState } from "react";
import { BeakerIcon, 
        BanknotesIcon, 
        GlobeAmericasIcon, 
        PresentationChartLineIcon,
        StarIcon,
        ScaleIcon,
        PencilSquareIcon
         } from "@heroicons/react/24/solid";

export default function Accordian({ title, content }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <div className="accordion-item">
        <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <div className="front-of-the-row">
            <div className="icon">
              {title === 'Laboratory Operations' ?<BeakerIcon className="h-6 w-6"/> : ''}
              {title === 'Laboratory Outreach Solutions' ?<GlobeAmericasIcon className="h-6 w-6"/> : ''}
              {title === 'Revenue Cycle Management' ?<BanknotesIcon className="h-6 w-6"/> : ''}
              {title === 'Analytics and Reporting Tools' ?<PresentationChartLineIcon className="h-6 w-6"/> : ''}
              {title === 'Quality Management' ?<PencilSquareIcon className="h-6 w-6"/> : ''}
              {title === 'System Laboratory Alignment' ?<ScaleIcon className="h-6 w-6"/> : ''}
              {title === 'Healthcare Compliance' ?<StarIcon className="h-6 w-6"/> : ''}
            </div>
            <div className="font-sm md:font-base">{title}</div>
          </div>
          <div>{isActive ? "-" : "+"}</div>
        </div>
        {isActive && (
          <div className="accordion-content border border-solid mb-1 font-sm md:font-base rounded shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)]">
            {content}
          </div>
        )}
      </div>
    </>
  );
}
