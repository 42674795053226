import Select from "react-select";
import Review from "./review";
import { useState, useEffect } from "react";

export default function Payment() {
  /***** 

    API CALL TO POPULATE DROPDOWNS

  *****/

  // get 'state' list from the api
  const [states, setStates] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/states`, { mode: `cors` })
      .then((res) => res.json())
      .then((states) => setStates(states))
      .catch((err) => console.log(err));
  }, []);

  // creating ArrayObj options from api data to load dropdown options
  let stateOptions = [];
  for (const key of states) {
    stateOptions.push({
      value: key["abbr"],
      label: key["abbr"],
    });
  }

  /*****
  
    HANDLE DROPDOWN STATE CHANGES

  *****/

  const [stateSelectedAbbrOption, setSelectedStateAbbr] = useState(null);

  const handleChange = (item) => {
    setSelectedStateAbbr(item);
  };

  return (
    <>
      <div className="md:flex md:flex-row">
        <div className="border border-solid m-3 p-2 pb-3 md:w-full md:w-1/2 md:ml-10 md:p-3 md:pb-5 rounded bg-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.10)]">
          <div className="w-full text-xl mb-5">
            Payment Details
            <span className="text-mexican-orange">
              &nbsp;-- DO NOT FILL OUT
            </span>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full md:w-3/4 px-3 pt-2">
              <label>Full Name</label>
              <input
                type="text"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="fullName"
                placeholder="Full Name"
              ></input>
            </div>
            <div className="w-full md:w-2/4 px-3 pt-2">
              <label>Credit Card Number</label>
              <input
                type="text"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="creditCardNo"
                placeholder="XXXX XXXX XXXX XXXX"
              ></input>
            </div>
            <div className="w-full md:w-1/4 px-3 pt-2">
              <label>Expiration</label>
              <input
                type="text"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="expirationDate"
                placeholder="MM/YYYY"
              ></input>
            </div>
            <div className="w-full md:w-1/6 px-3 pt-2">
              <label>CVV</label>
              <input
                type="text"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="cvv"
                placeholder="CVV"
              ></input>
            </div>
            <div className="w-full md:w-3/4 px-3 pt-2">
              <label>Street Address</label>
              <input
                type="text"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="streetAddress"
                placeholder="Street Address"
              ></input>
            </div>

            <div className="w-full md:w-3/4 px-3 pt-2">
              <label>City</label>
              <input
                type="text"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="city"
                placeholder="City"
              ></input>
            </div>
            <div className="w-full md:w-1/4 px-3 pt-2"></div>
            <div className="w-full md:w-1/4 px-3 pt-2">
              <label>State</label>
              <Select
                value={stateSelectedAbbrOption}
                onChange={handleChange}
                options={stateOptions}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: "#e5e7eb",
                    lineHeight: "2rem",
                    borderColor: state.isFocused ? "none" : "none",
                    borderWidth: 0,
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: "#9CA3AF", // placeholder color
                  },
                })}
              />
            </div>
            <div className="w-full md:w-1/3 px-3 pt-2">
              <label>Zipcode</label>
              <input
                type="text"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="zipcode"
                placeholder="Zipcode"
              ></input>
            </div>
          </div>
        </div>
        <Review />
      </div>
    </>
  );
}
